import React from 'react'
import { NavLink } from 'react-router-dom'

const Basvuru = ({ theme }) => {
  return (
    <div className={`container w-full lg:h-96    mx-20 lg:rounded-2xl  m-10 flex flex-col lg:flex-row justify-center items-center gap-5 `}>
      <div
        className={`${theme ? "border border-white" : ""} w-full lg:w-1/2 h-40  rounded-xl  bg-black text-white py-5 pl-5 text-center border-r-2 border-black`}
      >
        <div className='text-lg mb-8'>Teknoloji şirketlerinden , Tarım şirketlerine kadar geleceğe yönelik projeleri olan çeşitli işletmeler ve kişilere destek sunuyoruz .</div>
        <div>
          Bilgilerinizi bizimle
          <NavLink
            to={'/yatirim'}
            className='text-purple-400 text-md cursor-pointer '
          >
            {' '}
            paylaşın{' '}
          </NavLink>{' '}
          sizin için yatırımcılara ulaşalım
        </div>
      </div>
      <div
        className={`w-full lg:w-1/2 ${theme ? "border border-white" : ""} h-40   rounded-xl  bg-black text-white py-5 pl-5 text-center border-r-2 border-black`}
      >
        <div className='text-lg mb-8'>Yatırımlarınızı geleceğe yönelik projelere aktarıyor ve geleceğin markalarını yaratıyoruz.</div>
        <div>
          Bilgilerinizi bizimle
          <NavLink
            to={'/yatirim'}
            className='text-purple-400 text-md cursor-pointer '
          >
            {' '}
            paylaşın{' '}
          </NavLink>{' '}
          sizin için en uygun projeleri bulalım
        </div>
      </div>

    </div>
  )
}

export default Basvuru
