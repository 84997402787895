import React from 'react'

const Cart = ({
  img,
  title,
  desc,
  link,
  row,
  textCover = false,
  showDesc,
  logo
}) => {
  return textCover ? (
    <div
      data-aos='fade-up'
      data-aos-anchor-placement='center-bottom'
      className={`h-auto lg:my-10 lg:h-[24rem] container text-white   p-5 overflow-y-auto  lg:overflow-hidden flex flex-col lg:flex-wrap  justify-center  items-center   border rounded-3xl  w-full ${row === 2 ? 'bg-slate-200' : 'bg-black'
        }`}
    >
      <div className=' w-full lg:w-1/2  p-2 lg:px-5  '>
        <img
          src={img}
          alt=''
          data-aos='fade-right'
          data-aos-offset='300'
          data-aos-easing='ease-in-sine'
          className=' object-cover object-center lg:h-80 md:h-72 w-full  lg:w-5/6 rounded-2xl  '
        />
      </div>
      <div className='w-full lg:w-1/2  flex flex-col justify-center  items-center'>
        <div
          className='text-3xl font-semibold text-white  '
          data-aos='fade-left'
        >
          {title}
        </div>
        {showDesc === 1 && (
          <div className='text-xl pt-2 lg:px-10 ' data-aos='fade-left'>
            {desc}...
          </div>
        )}
        {/* <div
          data-aos='fade-up'
          className='h-10 mt-5  text-center bg-indigo-400 hover:bg-indigo-600 cursor-pointer p-2 rounded-lg text-white font-semibold'
        >
          Devamına Git
        </div> */}
      </div>
    </div>
  ) : (
    <div
      data-aos='fade-up'
      data-aos-anchor-placement='center-bottom'
      className={`h-auto my-10 lg:h-[24rem] container  p-5 overflow-y-auto  lg:overflow-hidden flex flex-col lg:flex-wrap  justify-center  items-center   border rounded-3xl  w-full ${row === 2 ? 'bg-slate-200' : 'bg-black'
        }`}
    >
      <div className='w-full lg:w-1/2  flex flex-col justify-center  items-center'>
        {logo ? (
          <img
            src={`${logo}`}
            alt='logo'
            className=' object-cover object-center w-2/6 mx-auto '
          />
        ) : (
          <div
            className='text-3xl font-semibold text-black  '
            data-aos='fade-left'
          >
            {title}
          </div>
        )}

        {showDesc === 1 && (
          <div className='text-xl pt-2 lg:px-10 ' data-aos='fade-left'>
            {desc}...
          </div>
        )}

        {/* <div
          data-aos='fade-up'
          className='h-10 mt-5   text-center bg-indigo-400 hover:bg-indigo-600 cursor-pointer p-2 rounded-lg text-white font-semibold'
        >
          Devamına Git
        </div> */}
      </div>
      <div className=' w-full lg:w-1/2  p-2 lg:px-5  '>
        <img
          src={img}
          alt=''
          data-aos='fade-right'
          className=' object-cover object-center md:h-72 lg:h-80 w-full  lg:w-5/6 rounded-2xl  '
        />
      </div>
    </div>
  )
}

export default Cart
