import React, { useEffect, useRef, useState } from 'react'
import Header from '../layouts/header/Header'
import Cart from '../components/home-cart/Cart'
import Footer from '../layouts/footer/Footer'
import Basvuru from '../components/basvuru-cart/Basvuru'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {
  AiOutlineMenu,
  AiOutlineWhatsApp,
  AiOutlinePhone
} from 'react-icons/ai'
import { CiUnlock } from 'react-icons/ci'
import { Link } from 'react-router-dom'
const Projeler = ({ theme, setTheme }) => {
  const [openMenu, setOpenMenu] = useState(false)
  const myInputRef = useRef(null)

  useEffect(() => {
    myInputRef.current.focus()
    AOS.init()
  }, [])
  return (
    <div>
      <Header themeChange={setTheme} theme={theme} />
      <button
        data-aos='fade-up'
        data-aos-easing='ease-in-sine'
        onClick={() => setOpenMenu(!openMenu)}
        className='bg-slate-500 hover:bg-slate-700 hover:animate-spin text-white text-2xl font-bold py-4 px-4 rounded-full fixed-button'
      >
        {openMenu ? <CiUnlock /> : <AiOutlineMenu />}
      </button>
      {openMenu && (
        <div>
          <Link
            data-aos='fade-right'
            data-aos-easing='ease-in-sine'
            to={'https://api.whatsapp.com/send?phone=905321349003'}
            className='bg-green-500 hover:bg-green-700 text-white text-2xl font-bold py-4 px-4 rounded-full fixed-button1'
          >
            <AiOutlineWhatsApp />
          </Link>

          <Link
            to={'tel:+9005321349003'}
            data-aos='fade-left'
            data-aos-easing='ease-in-sine'
            className='bg-blue-500 hover:bg-blue-700 text-white text-2xl font-bold py-4 px-4 rounded-full fixed-button2'
          >
            <AiOutlinePhone />
          </Link>
        </div>
      )}
      <div className='flex flex-col rounded-2xl   w-full lg:px-10     justify-center items-center'>
        <div ref={myInputRef} tabIndex={0}></div>
        <Cart
          img={
            'https://maxistainremover.com/images/logo/346130872105-240-MAXI%20STAIN%20REMOVER-111-01.png'
          }
          row={2}
          title={'Endüstiriyel Üretim'}
          link={['hakkimizda']}
        />
        <Cart
          row={2}
          img={'https://bendebu.com/logo.png'}
          title={'Bende Bu'}
          link={['hakkimizda']}
        />
        <Basvuru />

        <Cart
          row={2}
          img={'https://konumu.com/image/logo.png'}
          title={'En Iyilerin Konumu'}
          link={['hakkimizda']}
        />
        <Footer />
      </div>
    </div>
  )
}

export default Projeler
