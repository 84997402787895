import React, { useState, useEffect } from 'react';


function HarfAnimasyon({ text, value = 1 }) {
    const [animatedText, setAnimatedText] = useState('');
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (text.length > index) {
            const timer = setTimeout(() => {
                setAnimatedText((prevText) => prevText + text[index]);
                setIndex((prevIndex) => prevIndex + 1);
            }, 40);

            return () => clearTimeout(timer);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, text]);

    return <div>{animatedText}</div>;
}

export default HarfAnimasyon;
