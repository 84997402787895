import React from 'react'

function Popup ({ status, setIsModalOpen }) {
  return (
    status && (
      <div className='fixed w-full inset-0  z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
        <div className='relative w-full md:w-1/2  mx-auto my-6'>
          {/* Modal İçeriği */}
          <div className='modal relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg'>
            {/* Modal Başlığı */}
            <div className='modal-header px-4 py-2 border-b border-solid border-gray-300'>
              <h3 className='text-lg font-semibold text-center'>
                29 Ekim Cumhuriyet Bayramımız Kutlu olsun
              </h3>
              <button
                onClick={() => setIsModalOpen(false)}
                className='modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-black transition duration-150 transform hover:scale-110 hover:text-red-500'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-6 h-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>
            {/* Modal İçeriği */}
            <div className='modal-body p-4'>
              <img src='/img/tasarım.jpeg' alt='popupImg' />
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default Popup
