import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = ({ theme }) => {
  return (
    <div className={` ${theme ? 'border border-white' : ''} relative container flex lg:flex-row  flex-col mb-10 w-full lg:h-72 bg-black text-white   mx-20 lg:rounded-2xl    m-10w-full`}>
      <div className='flex flex-col mt-10  w-full lg:w-1/3 pl-3 justify-start items-center'>
        <div
          data-aos='fade-right'
          data-aos-offset='200'
          data-aos-easing='ease-in-sine'
          className='w-full text-2xl mb-5 lg:text-4xl flex justify-center items-center text-center '
        >
          <img src='/img/logofooter.png' className='w-72' alt='' />
        </div>
        <div data-aos='fade-up' className='w-full text-md  text-center '>
          Fuat Ayaz Group Bilişim Teknolojileri A.Ş., ileri teknoloji
          çözümleriyle donanmış köklü ve yenilikçi bir şirketler grubudur .
          Fikirlerinizi destekliyor yatırım sağlayarak iş hayatınızda bir adım
          önde olmanızı hedefliyoruz.
        </div>
      </div>
      <div className='flex flex-col w-full lg:w-1/3 mt-5 lg:pl-20 lg:mt-10 lg:mx-10 justify-start  items-center'>
        <div
          data-aos='fade-up'
          data-aos-offset='200'
          data-aos-easing='ease-in-sine'
          className='flex flex-wrap text-center  '
        >
          <div className='w-full text-center lg:text-left text-2xl mb-2'>
            İletişim Bilgileri
          </div>
          <div className='w-full text-center lg:text-left text-lg mb-2'>
            Telefon : 0532 134 90 03
          </div>
          <div className='w-full text-center lg:text-left text-lg mb-2'>
            Telefon : 0850 308 58 89
          </div>
          <div className='w-full text-center lg:text-left text-lg mb-2'>
            E-Posta : info@fuatayazgroup.com
          </div>
        </div>
      </div>
      <div className='flex flex-col w-full lg:w-1/3 justify-center lg:justify-start mt-10 items-center'>
        <div
          data-aos='fade-left'
          data-aos-offset='200'
          data-aos-easing='ease-in-sine'
          className='w-full mb-5 text-2xl  text-center lg:text-left'
        >
          İletişime Geçin
        </div>
        <div className='flex flex-col w-full justify-center lg:justify-start  lg:items-start items-center mb-10 lg:mb-0 '>
          <span className='w-full text-center lg:text-left'>
            Aklınıza takılan her konuda sorularınızı cevaplamak için buradayız.
          </span>
          <NavLink to={'/iletisim'} className='text-purple-400'>
            buradan
          </NavLink>{' '}
          <span>bizimle iletişime geçebilirsiniz.</span>
        </div>
      </div>

      <div className='absolute bottom-2 text-white w-full flex justify-end items-center px-3 lg:px-10 '>
        2023 Bütün Hakları Saklıdır
      </div>
    </div>
  )
}

export default Footer
