import React, { useEffect, useRef, useState } from 'react'
import Header from '../layouts/header/Header'

import Footer from '../layouts/footer/Footer'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {
  AiOutlineMenu,
  AiOutlineWhatsApp,
  AiOutlinePhone
} from 'react-icons/ai'
import { CiUnlock } from 'react-icons/ci'
import { Link } from 'react-router-dom'
const Yatirim = ({ theme, setTheme }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const [step, setStep] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const myInputRef = useRef(null)

  const [keyword, setKeyword] = useState({
    title: '',
    input1: '',
    input2: '',
    input3: '',
    input4: '',
    input5: '',
    input6: '',
    input7: ''
  })
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    companyName: '',
    faliyet: '',
    phone: '',
    email: '',
    msg: '',
    value: ''
  })

  const onChangeHandler = event => {
    const { name, value } = event.target

    setFormData({ ...formData, [name]: value })
  }

  const formSubmitHandler = event => {
    event.preventDefault()
    setIsLoading(!isLoading)
    fetch('https://fuatayazgrup.com/api/yatirim.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        setResponse(data)
      })
  }
  useEffect(() => {
    myInputRef.current.focus()

    AOS.init()
  }, [])

  useEffect(() => {
    if (!step) {
      setKeyword({
        ...keyword,
        title: 'Yatırım İsteme Formu',
        input1: 'Adınız',
        input2: 'Soyadınız',
        input3: 'Şirket Adı',
        input4: 'Mail Adresiniz',
        input5: 'Telefon Numaranız',
        input6: 'Faliyet Alanı',
        input7: 'Bize Şirketinizden Bahseder misiniz ?'
      })
    } else {
      setKeyword({
        ...keyword,
        title: 'Yatırım Oluşturma Formu',
        input1: 'Adınız',
        input2: 'Soyadınız',
        input3: 'Mail Adresiniz',
        input4: 'Telefon Numaranız',
        input5: 'Faliyet Alanı',
        input6: 'Yatırım Bütçeniz',
        input7: 'Bize Şirketinizden Bahseder misiniz ?'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  useEffect(() => {
    if (response) {
      setIsLoading(!isLoading)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  return (
    <div>
      <Header themeChange={setTheme} theme={theme} />
      <button
        data-aos='fade-up'
        data-aos-easing='ease-in-sine'
        onClick={() => setOpenMenu(!openMenu)}
        className='bg-slate-500 hover:bg-slate-700 hover:animate-spin text-white text-2xl font-bold py-4 px-4 rounded-full fixed-button'
      >
        {openMenu ? <CiUnlock /> : <AiOutlineMenu />}
      </button>
      {openMenu && (
        <div>
          <Link
            data-aos='fade-right'
            data-aos-easing='ease-in-sine'
            to={'https://api.whatsapp.com/send?phone=905321349003'}
            className='bg-green-500 hover:bg-green-700 text-white text-2xl font-bold py-4 px-4 rounded-full fixed-button1'
          >
            <AiOutlineWhatsApp />
          </Link>

          <Link
            to={'tel:+9005321349003'}
            data-aos='fade-left'
            data-aos-easing='ease-in-sine'
            className='bg-blue-500 hover:bg-blue-700 text-white text-2xl font-bold py-4 px-4 rounded-full fixed-button2'
          >
            <AiOutlinePhone />
          </Link>
        </div>
      )}
      <div className='flex  flex-col rounded-2xl   w-full      justify-center items-center'>
        <label className='relative inline-flex items-center mb-4 cursor-pointer'>
          <input
            onChange={() => setStep(!step)}
            checked={step}
            type='checkbox'
            className='sr-only peer'
          />
          <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
          <span className='ml-3 text-sm font-medium  '>Yatırım Yap</span>
        </label>
        <form
          onSubmit={formSubmitHandler}
          ref={myInputRef}
          tabIndex={0}
          className=' container h-[600px] rounded-lg mb-10  flex flex-col justify-center items-cente  bg-gradient-to-tl text-black   from-indigo-100 to-slate-200 w-full '
        >
          <span data-aos='fade-up' className='w-full mt-5 text-center text-2xl'>
            {keyword.title}
          </span>
          <div className='flex flex-wrap justify-center items-center w-full my-10'>
            <div className='lg:w-1/3 w-full mx-5 mb-5'>
              <div
                data-aos='fade-right'
                className='relative h-11 w-full min-w-[200px]'
              >
                <input
                  value={formData.name}
                  onChange={onChangeHandler}
                  name='name'
                  className='peer h-full w-full border-b border-black bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                  placeholder=' '
                />
                <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  {keyword.input1}
                </label>
              </div>
            </div>
            <div className='lg:w-1/3 w-full mx-5 mb-5'>
              <div
                data-aos='fade-left'
                className='relative h-11 w-full min-w-[200px]'
              >
                <input
                  value={formData.lastname}
                  onChange={onChangeHandler}
                  name='lastname'
                  className='peer h-full w-full border-b border-black bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                  placeholder=' '
                />
                <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  {keyword.input2}
                </label>
              </div>
            </div>
            <div className='lg:w-1/3 w-full mx-5 mb-5'>
              <div
                data-aos='fade-right'
                className='relative h-11 w-full min-w-[200px]'
              >
                <input
                  value={formData.companyName}
                  onChange={onChangeHandler}
                  name='companyName'
                  className='peer h-full w-full border-b border-black bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                  placeholder=' '
                />
                <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  {keyword.input3}
                </label>
              </div>
            </div>
            <div className='lg:w-1/3 w-full mx-5 mb-5'>
              <div
                data-aos='fade-left'
                className='relative h-11 w-full min-w-[200px]'
              >
                <input
                  value={formData.email}
                  onChange={onChangeHandler}
                  name='email'
                  className='peer h-full w-full border-b border-black bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                  placeholder=' '
                />
                <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  {keyword.input4}
                </label>
              </div>
            </div>
            <div className='lg:w-1/3 w-full mx-5 mb-5'>
              <div
                data-aos='fade-right'
                className='relative h-11 w-full min-w-[200px]'
              >
                <input
                  value={formData.phone}
                  onChange={onChangeHandler}
                  name='phone'
                  className='peer h-full w-full border-b border-black bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                  placeholder=' '
                />
                <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  {keyword.input5}
                </label>
              </div>
            </div>
            <div className='lg:w-1/3 w-full mx-5 mb-5'>
              <div
                data-aos='fade-left'
                className='relative h-11 w-full min-w-[200px]'
              >
                <input
                  value={formData.faliyet}
                  onChange={onChangeHandler}
                  name='faliyet'
                  className='peer h-full w-full border-b border-black bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                  placeholder=' '
                />
                <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  {keyword.input6}
                </label>
              </div>
            </div>
            <div className='lg:w-2/3 w-full mx-5  mb-5'>
              <div
                data-aos='fade-right'
                className='relative h-11 w-full min-w-[200px]'
              >
                <textarea
                  value={formData.msg}
                  onChange={onChangeHandler}
                  name='msg'
                  className='peer h-full w-full border-b border-black bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                  placeholder=' '
                />
                <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  {keyword.input7}
                </label>
              </div>
            </div>
            <div className='w-full mx-5 lg:w-2/3 '>
              <button
                data-aos='fade-up'
                type='submit'
                className=' w-1/2 lg:w-2/6 bg-indigo-400 text-center rounded-xl text-white h-10 flex items-center justify-center'
              >
                {!response ? (
                  isLoading ? (
                    <span>
                      <svg
                        aria-hidden='true'
                        role='status'
                        className='inline w-4 h-4 mr-3 text-white animate-spin'
                        viewBox='0 0 100 101'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                          fill='#E5E7EB'
                        />
                        <path
                          d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                          fill='currentColor'
                        />
                      </svg>
                      Loading...
                    </span>
                  ) : (
                    `Gönder`
                  )
                ) : isLoading ? (
                  <span>
                    <svg
                      aria-hidden='true'
                      role='status'
                      className='inline w-4 h-4 mr-3 text-white animate-spin'
                      viewBox='0 0 100 101'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                        fill='#E5E7EB'
                      />
                      <path
                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                        fill='currentColor'
                      />
                    </svg>
                    Loading...
                  </span>
                ) : response.succ ? (
                  response.msg
                ) : (
                  <span className='text-red-300'>{response.msg}</span>
                )}
              </button>
            </div>
          </div>
        </form>

        <Footer />
      </div>
    </div>
  )
}

export default Yatirim
