import React, { useEffect, useRef, useState } from 'react'
import Header from '../layouts/header/Header'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Banner from '../components/banner-cart/Banner'
import Basvuru from '../components/basvuru-cart/Basvuru'
import Footer from '../layouts/footer/Footer'
import {
  AiOutlineMenu,
  AiOutlineWhatsApp,
  AiOutlinePhone
} from 'react-icons/ai'
import { CiUnlock } from 'react-icons/ci'
import { Link, NavLink } from 'react-router-dom'
import Popup from '../components/popup/Popup'
import { Container } from 'react-bootstrap'
import Projelercart from '../components/home-cart/Projelercart'

const Home = ({ theme, setTheme }) => {
  const [openMenu, setOpenMenu] = useState(false)
  const myInputRef = useRef(null)

  useEffect(() => {
    myInputRef.current.focus()

    AOS.init()
  }, [])
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    // Sayfa yüklendiğinde pop-up'ı açmak için useEffect kullanıyoruz.
    setShowPopup(false)
  }, [])
  const closePopup = () => {
    setShowPopup(false)
  }

  return (
    <div >
      <Header themeChange={setTheme} theme={theme} />
      <button
        data-aos='fade-up'
        data-aos-easing='ease-in-sine'
        onClick={() => setOpenMenu(!openMenu)}
        className='bg-slate-500 hover:bg-slate-700 hover:animate-spin text-white text-2xl font-bold py-4 px-4 rounded-full fixed-button'
      >
        {openMenu ? <CiUnlock /> : <AiOutlineMenu />}
      </button>
      {openMenu && (
        <div>
          <Link
            data-aos='fade-right'
            data-aos-easing='ease-in-sine'
            to={'https://api.whatsapp.com/send?phone=905321349003'}
            className='bg-green-500 hover:bg-green-700 text-white text-2xl font-bold py-4 px-4 rounded-full fixed-button1'
          >
            <AiOutlineWhatsApp />
          </Link>

          <Link
            to={'tel:+9005321349003'}
            data-aos='fade-left'
            data-aos-easing='ease-in-sine'
            className='bg-blue-500 hover:bg-blue-700 text-white text-2xl font-bold py-4 px-4 rounded-full fixed-button2'
          >
            <AiOutlinePhone />
          </Link>
        </div>
      )}

      <div ref={myInputRef} className='flex flex-col  w-full lg:px-10    justify-center items-center'>
        <Banner theme={theme} />
        <Popup status={showPopup} setIsModalOpen={setShowPopup} />

        <Basvuru theme={theme} />
        <div
          data-aos='fade-right'
          data-aos-offset='300'
          data-aos-easing='ease-in-sine'
          className=' text-2xl lg:text-3xl mt-5 font-semibold'
        >
          Destek Verdiğimiz Projeler
        </div>
        <Projelercart img='/img/bendebu.png' title='Bendebu' text={"Bir cok alım satım platformunun geliştirilerek daha kullanışlı hale getirilip kullanıcılara eşsiz bir deneyim sunan bir uygulama sana ne lazımsa bendebu.com"} />

        <Projelercart img='/img/sorgubox.png' title='Sorgubox' text={"Teknolojiyi kullanırken güvenlik her zaman öncelik olmalıdır. Mobil Cihaz Güvenliği Platformumuz, size akıllı telefonlarınızın ve diğer mobil cihazlarınızın güvenliğini sağlamanız için gereken her şeyi sunuyor."} />

        <Projelercart img='/img/konumu.png' title='konumu' text={"Sizin için en güzel ve ilginç mekanları bulmak artık çok kolay! Uygulamamız sayesinde bulunduğunuz lokasyonda size en yakın ve en popüler mekanları keşfedebilirsiniz. Yorumlar, fotoğraflar ve önerilerle mekanları daha yakından tanıyabilir, keyifli zaman geçirebilirsiniz."} />


        <Container className='flex  sm:h-72 lg:h-80 xl:h-96 flex-col gap-4 mb-10 justify-center items-center'>
          <h3 className='text-2xl font-semibold'>Hakkımızda</h3>
          <p className='font-semibold w-full md:w-2/3 px-5 ' >Fuat Ayaz Group, farklı sektörlerde faaliyet gösteren işletmelerin geniş finansal ihtiyaçlarına destek sağlar. Başarı odaklı yaklaşımı ve kapsamlı finansal çözümleriyle işletmelere destek sağlarken sektörler arası işbirliğini güçlendirir. Geniş bir sektörel yelpazede faaliyet gösterir, Teknoloji şirketlerinden Tarım şirketlerine kadar geleceğe yönelik projeleri olan çeşitli işletmeler ve kişilere destek sunar. Bu yaklaşım, sürdürülebilirliği destekleyerek güçlü bir ekonomik altyapı oluşturur. Fuat Ayaz Group Finansal çözüm arayışında olanlar için önemli bir kaynak konumundadır.</p>

        </Container>
        <Footer theme={theme} />
      </div>
    </div>
  )
}

export default Home

