import React, { useState } from 'react'
import { AiOutlineMenuFold } from 'react-icons/ai'
import { IoExitOutline } from 'react-icons/io5'
import { Link, NavLink } from 'react-router-dom'
import { BsInstagram } from 'react-icons/bs'
import { FaXTwitter, FaWhatsapp } from 'react-icons/fa6'
import { WiMoonAltThirdQuarter } from "react-icons/wi";
const Header = ({ themeChange, theme }) => {
  const [mobileMenu, setMobileMenu] = useState(false)
  return (
    <div
      className={` rounded-md lg:mb-12  ${mobileMenu ? 'h-56 justify-start pt-2' : 'h-14 justify-center'
        } xl:mx-20 lg:mx-16 md:mx-10 sm:mx-0  z-20 flex flex-col  items-center ${theme ? 'text-white ' : ''} `}
    >
      <div className='container flex flex-row  justify-between pr-5 lg:px-10'>
        <NavLink to={'/'} className=''>
          <img
            className='lg:w-[300px] w-[200px] '
            src={`${theme ? "/img/logofooter.png" : "/img/fuatyaz.png"}`}
            alt=''
          />
        </NavLink>
        <div className='text-lg font-semibold lg:flex lg:flex-row justify-center items-center hidden lg:visible '>
          <NavLink
            to={'/'}
            className='px-4 hover:bg-slate-200 hover:rounded-xl  '
          >
            Anasayfa
          </NavLink>
          <NavLink
            to={'/hakkimizda'}
            className='px-4 hover:bg-slate-200 hover:rounded-xl'
          >
            Hakkımızda
          </NavLink>
          {/* <NavLink
            to={'/projeler'}
            className='px-4 hover:bg-slate-200 hover:rounded-xl'
          >
            Projelerimiz
          </NavLink> */}
          <NavLink
            to={'/yatirim'}
            className='px-4 hover:bg-slate-200 hover:rounded-xl'
          >
            Yatırım
          </NavLink>

          <NavLink
            to={'/iletisim'}
            className='px-4 hover:bg-slate-200 hover:rounded-xl'
          >
            İletişim
          </NavLink>
        </div>
        <div className='text-lg font-semibold lg:flex lg:flex-row justify-center items-center hidden lg:visible '>
          <Link target='_blank' to={'https://www.instagram.com/fuatayazgroup/'}>
            <BsInstagram className='mx-2 w-10 h-6' />
          </Link>
          <Link
            target='_blank'
            to={'https://api.whatsapp.com/send?phone=905321349003'}
          >
            <FaWhatsapp className='mx-2 w-10 h-6' />
          </Link>
          <Link target='_blank' to={'https://www.instagram.com/fuatayazgroup/'}>
            <FaXTwitter className='mx-2 w-10 h-6' />
          </Link>
          <div className=' cursor-pointer ' onClick={() => themeChange(!theme)} ><WiMoonAltThirdQuarter className='mx-2 w-10 h-8' /></div>
        </div>
        <div className='text-3xl visible lg:invisible'>
          {mobileMenu ? (
            <IoExitOutline onClick={() => setMobileMenu(!mobileMenu)} />
          ) : (
            <AiOutlineMenuFold onClick={() => setMobileMenu(!mobileMenu)} />
          )}
        </div>
      </div>
      {mobileMenu && (
        <div className='container z-40  flex flex-col mt-1 justify-between items-center text-xl px-10'>
          <NavLink
            to={'/'}
            className='px-4 hover:bg-slate-200 hover:rounded-xl  '
          >
            Anasayfa
          </NavLink>
          <NavLink
            to={'/hakkimizda'}
            className='px-4 hover:bg-slate-200 hover:rounded-xl'
          >
            Hakkımızda
          </NavLink>
          {/* <NavLink
            to={'/projeler'}
            className='px-4 hover:bg-slate-200 hover:rounded-xl'
          >
            Projelerimiz
          </NavLink> */}
          <NavLink
            to={'/yatirim/al'}
            className='px-4 hover:bg-slate-200 hover:rounded-xl'
          >
            Yatırım İsteyin
          </NavLink>
          <NavLink
            to={'/yatirim/yap'}
            className='px-4 hover:bg-slate-200 hover:rounded-xl'
          >
            Yatırım Yapın
          </NavLink>
          <NavLink
            to={'/iletisim'}
            className='px-4 hover:bg-slate-200 hover:rounded-xl'
          >
            İletişim
          </NavLink>
        </div>
      )}
    </div>
  )
}

export default Header
