import React, { useEffect, useState } from 'react'
function TextList({ textList }) {
  const [currentText, setCurrentText] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    if (currentIndex < textList.length) {
      const text = textList[currentIndex]
      const currentCharIndex = currentText.length

      if (currentCharIndex < text.length) {
        const timeoutId = setTimeout(() => {
          setCurrentText(text.substring(0, currentCharIndex + 1))
        }, 130)

        return () => clearTimeout(timeoutId)
      } else {
        setTimeout(() => {
          setCurrentIndex((currentIndex + 1) % textList.length)
          setCurrentText('')
        }, 1000)
      }
    }
  }, [currentText, currentIndex, textList])

  return <p className='  italic cursive '>{currentText}</p>
}
const Banner = ({ theme }) => {
  const textlist = [
    'Önem veriyoruz',
    'Değerlendiriyoruz',
    'Yatırım yapıyoruz',
    'Geleceğe taşıyoruz'
  ]
  return (
    <div className={`container w-full lg:h-96  mx-20 lg:rounded-2xl    bg-black text-white flex flex-col lg:flex-row ${theme ? 'border border-white' : ''} `}>
      <div className='w-full lg:w-1/2  lg:pl-40 flex flex-col justify-center'>
        <div
          data-aos='fade-right'
          data-aos-offset='100'
          data-aos-easing='ease-in-sine'
          className='lg:text-5xl text-center text-3xl pl-5 mb-5'
        >
          <img
            src='/img/logofooter.png'
            className='w-5/6  mx-5 object-cover object-center'
            alt=''
          />
        </div>
      </div>
      <div
        data-aos='fade-up'
        data-aos-offset='200'
        data-aos-easing='ease-in-sine'
        className='w-full  lg:w-1/2 py-10  lg:p-20 flex  flex-col justify-center items-center'
      >
        <div className='text-center text-2xl lg:text-2xl 2xl:text-4xl  mb-5 w-full  flex'>
          <span className='mx-5 text-red-500 '>Sizin İçin </span>
          <TextList textList={textlist} />
        </div>
      </div>
    </div>
  )
}

export default Banner
