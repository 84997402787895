
import { useState } from "react";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Projeler from "./pages/Projeler";
import Yatirim from "./pages/Yatirim";

const { Routes, Route } = require("react-router-dom");


function App() {
  const [theme, setTheme] = useState(false)
  return (
    <div className={`${theme ? 'bg-black text-white ' : ''}`} >
      <Routes>
        <Route path="/hakkimizda" element={<About theme={theme} setTheme={setTheme} />} />
        <Route path="/projeler" element={<Projeler theme={theme} setTheme={setTheme} />} />
        <Route path="/yatirim" element={<Yatirim theme={theme} setTheme={setTheme} />} />
        <Route path="/iletisim" element={<Contact theme={theme} setTheme={setTheme} />} />
        <Route path="/" element={<Home theme={theme} setTheme={setTheme} />} />
      </Routes>
    </div>


  );
}

export default App;
